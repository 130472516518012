import { faComments, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camelCase } from 'lodash-es';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';

import EnvSettings from '../../../api/EnvSettings';
import { TICKET_WINDOW_WIDTH } from 'src/Components/generic/FloatingWindow/constants';
import { addFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import { FloatingWindowTypes } from 'src/types/FloatingWindow';

const AiAssistantButton = () => {
  const dispatch = useDispatch();
  const { AI_ASSISTANT: aiAssistantSettings } = EnvSettings.getSettings();
  const { name, iconName } = aiAssistantSettings?.data ?? {};

  const openAiAssistant = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      addFloatingWindow({
        title: name,
        id: FloatingWindowTypes.AIAssistant,
        type: FloatingWindowTypes.AIAssistant,
        width: TICKET_WINDOW_WIDTH,
        data: {} as any
      })
    );
  };

  return (
    <div>
      <Button onClick={openAiAssistant}>
        <FontAwesomeIcon icon={fas[camelCase(iconName)] ?? faComments} />
      </Button>
    </div>
  );
};

export default AiAssistantButton;
