import iziToast from 'izitoast';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import { getSurveyAnswers, getSurveyLink } from './Api/SurveyRequest';
import Error from './Error';
import Notification from './Notification';
import Spinner from './Spinner';
import SurveyList from './SurveyList';

import type { SurveyAnswer } from 'src/types/Survey';

interface SurveyContainerProps {
  taskId: string;
}

export const SurveyContainer: FC<SurveyContainerProps> = ({ taskId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'notification'>('loading');
  const [surveys, setSurveys] = useState<SurveyAnswer[]>([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const taskNumber = taskId.match(/\d+/g)?.toString();
    if (taskNumber !== undefined) {
      setSurveys([]);
      setStatus('loading');

      getSurveyAnswers(taskNumber)
        .then((data) => {
          if (Array.isArray(data) && data[0] !== undefined) {
            setSurveys(data);
            setStatus('success');
          } else {
            setStatus('notification');
            setNotification(t('CASE_SURVEY_NOT_FOUND'));
          }
        })
        .catch((error: any) => {
          console.error('Error while getting survey', error.data);
          if (error.status === 404) {
            setStatus('notification');
            setNotification('notification');
          } else {
            setStatus('error');
            setNotification(error.message);
          }
        });
    } else {
      setStatus('notification');
      setNotification(t('CASE_SURVEY_NOT_FOUND'));
    }
  }, [taskId]);

  const onCopyClick = useCallback(() => {
    getSurveyLink(taskId).then((link) => {
      navigator.clipboard.writeText(link);
      iziToast.info({
        message: t('COPIED')
      });
    });
  }, [taskId]);

  return (
    <div>
      {status === 'error' && <Error taskId={taskId} className="error" text={notification} />}
      {status === 'loading' && <Spinner taskId={taskId} className="loading" />}
      {status === 'success' && <SurveyList taskId={taskId} surveys={surveys} />}
      {status === 'notification' && (
        <Notification type={['negative']} taskId={taskId} className="error" text={notification} />
      )}

      {status !== 'loading' && status !== 'error' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button primary icon onClick={onCopyClick}>
            <Icon name="copy" /> {t('Copy survey link')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(SurveyContainer);
